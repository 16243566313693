import WOW from 'wow.js';

export const animation = () => {
  const wow = new WOW(
    {
      animateClass: 'animate__animated',
    }
  )
  wow.init();
}
