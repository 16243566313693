export const accordion = () => {
  if($('.js-accordion').length) {
    $('.js-accordion-header').on('click', function() {
      const $this = $(this);
      if($this.hasClass('active')) {
        $this.removeClass('active');
        $this.siblings('.js-accordion-content').addClass('show');
        $this.siblings('.js-accordion-content').slideToggle();
      } else {
        $this.siblings('.js-accordion-content').removeClass('show');
        $this.siblings('.js-accordion-content').slideToggle();
        $this.addClass('active');
      }
    })
  }
}
