
export const numberCount = () => {
  let viewed = false;
  
  const isScrolledIntoView = (elem) => {
    const docViewTop = $(window).scrollTop();
    const docViewBottom = docViewTop + $(window).height();
  
    const elemTop = $(elem).offset().top;
    const elemBottom = elemTop + $(elem).height();
  
    return elemBottom <= docViewBottom && elemTop >= docViewTop;
  }
  
  const testScroll = () => {
    const numbersWrap = $(".wrapper_numbers");
    if (
      numbersWrap.length &&
      isScrolledIntoView(numbersWrap) &&
      !viewed
    ) {
      viewed = true;
      $(".js-value").each(function () {
        $(this)
          .prop("Counter", 0)
          .animate(
            {
              Counter: $(this).data("num"),
            },
            {
              duration: 3000,
              easing: "swing",
              step: function (now) {
                $(this).text(Math.ceil(now));
              },
              complete: function (now) {
                if ($(this).hasClass("percent-value")) {
                  $(this).siblings(".percent").addClass("js-active");
                }
              },
            }
          );
      });
    }
  }

  $(window).on('scroll', testScroll);
}
