import mixitup from 'mixitup';

export const filter = () => {
  $('.js-filter-item').on('click', function() {
    const $this = $(this); 
    $this.closest('.js-filter').toggleClass('show');
    $this.addClass('active').siblings().removeClass('active');
  });
  
  if ($('div').is('.js-filter-container')) {
    let mixer = mixitup('.js-filter-container');
  }
}
