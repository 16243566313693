export const navigation = () => {
  const bodyElem = $('body');
  const navWrap = $('.header');
  const isTransparent = navWrap.hasClass('transparent');
  let offsetTop = navWrap.offset().top;

  $('.navigation__icon').on('click', () => {
    bodyElem.toggleClass('closed opened');
    if (offsetTop <= 8 && isTransparent) {
      navWrap.toggleClass('has-bg')
    }
  });

  // close hamburger menu on item click
  $('.navigation__list a').on('click', () => {
    body.removeClass('opened')
  });

  // add bg to nav when scrolling down
  const stickyNav = () => {
    offsetTop = navWrap.offset().top;

    if (offsetTop > 8) {
      navWrap.addClass('has-bg');
    } else if (offsetTop <= 8 && !bodyElem.hasClass('opened') && isTransparent) {
      navWrap.removeClass('has-bg');
    }
  };

  stickyNav();
  $(window).on('scroll', () => stickyNav());
}
