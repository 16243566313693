export const fileInput = () => {
  let fileLabelText;
  
  const fileTemplate = file => `
    <div class="form-file__info">
      <span>${file.name}</span>
    </div>
  `;
  
  $('.js-input-file:not([multiple])')
    .one('change', ({ target }) => {
      fileLabelText = $(target).next('label').text();
    })
    .on('change', ({ target }) => {
      const file = target.files[0];
      const text = file ? `Chosen file '${file.name}'` : fileLabelText;
      const error = file && ''; 
      $(target).closest('.inputFile').find('label').text(text);
      $(target).next('span').text(error);
      $(target).parent().addClass('hasFile');
    });
  
  $('.js-input-file[multiple]')
    .on('change', ({ target }) => {
      const filesHtml = Object.values(target.files).map(fileTemplate).join('');
      $('.js-files').addClass('active');
      $(target).closest('.form-field').find('.js-new-files').html(filesHtml);
    });
  
  $('.js-input-file-refresh').on('click', ({ target }) => {
    const $labelFile = $(target).closest('.inputFile').find('.inputFile__label');
    const $inputFile = $(target).closest('.inputFile').find('.js-input-file');
  
    $inputFile.val('');
    $labelFile.text($inputFile.attr('placeholder'));
    $(target).closest('.inputFile').removeClass('hasFile');
  });
}
