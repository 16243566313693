export const collapse = () => {
  const btn = $('.js-collapse-btn');
  const btnText = $('.js-change-text');
  const content = $('.js-collapse-content');
  const defaultHeight = `${content.innerHeight()}px`;
  content.css('max-height', '130px');
  let collapseMode = true;
  btn.on('click', () => {
    collapseMode = !collapseMode;
    if (collapseMode) {
      content.css('max-height', '130px');
      btnText.text('more');
      btn.removeClass('active');
    } else {
      content.css('max-height', `${defaultHeight}`);
      btnText.text('less');
      btn.addClass('active');
      
    }
  })
}
