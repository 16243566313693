import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel';

export const owlSliders = () => {
  const autoPlayTimer = 8000;
  const slider = $('.slider');

  const changeTimer = () => {
    slider.trigger('stop.owl.autoplay');
    slider.trigger('play.owl.autoplay');
  }
  slider.owlCarousel({
    items: 1,
    loop: true,
    autoplay: true,
    autoplayTimeout: autoPlayTimer,
    nav: false,
    dots: true,
    navSpeed: 800,
    smartSpeed: 1000,
    onDragged: changeTimer,
    onChange: changeTimer
  });
  
  $('.js-client-carousel').owlCarousel({
    items: 1,
    loop: true,
    nav: true,
    navText: ['<i class="icon-prev"></i>','<i class="icon-next"></i>'],
    dots: true,
    navSpeed: 800,
    autoHeight: false,
    responsive: {
      565: {
        items: 1,
      }
    },
  });
  
  $('.js-gallery-slider').owlCarousel({
    items: 5,
    loop: false,
    dots: true,
    navSpeed: 800,
    autoHeight: true,
    responsive: {
      0: {
        autoHeight: true,
        nav: true,
        navText: ['<i class="icon-prev"></i>','<i class="icon-next"></i>'],
        dots: false,
        items: 1
      },
    },
  });
  
  $('.js-coaches-slider').owlCarousel({
    items: 1,
    loop: false,
    dots: false,
    nav: true,
    navSpeed: 800,
    autoHeight: true,
    navText: ['<i class="icon-prev"></i>','<i class="icon-next"></i>'],
    responsive: {
      768: {
        items: 2
      },
      1024: {
        items: 2
      },
    },
  });
  
  $('.js-specialize-in-slider').owlCarousel({
    items: 2,
    loop: false,
    dots: true,
    navSpeed: 800,
    slideBy: 2,
    autoHeight: true,
    navText: ['<i class="icon-prev"></i>','<i class="icon-next"></i>'],
    responsive: {
      768: {
        items: 4,
        nav: true,
      },
      1024: {
        items: 5,
        nav: true,
      },
      1200: {
        items: 7,
        nav: true,
      },
    },
  });
}
