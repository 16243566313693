import "jquery-validation";

export const validation = () => {
  $('.js-form').validate({
    errorElement: 'span',
    rules: {
      name: {
        required: true
      },
      email: {
        required: true,
        email: true
      },
      password: {
        required: true,
        minlength: 6
    },
    file: {
      required: true,
      extension: "txt|rtf|pdf|doc|docx"
    },
    
  }});

  $( ".js-required-field" ).rules( "add", {
    required: true,
  });
}
