import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel';

export const slickSliders = () => {
  $('.js-slick-nav').slick({
    centerMode: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    asNavFor: '.js-slick-for',
    dots: false,
    arrows: false,
    centerMode: true,
    focusOnSelect: true
  });
  
  $('.js-slick-for').slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    dots: true,
    fade: true,
    prevArrow: '<i class="icon-prev"></i>',
    nextArrow: '<i class="icon-next"></i>',
    asNavFor: '.js-slick-nav',
    responsive: [
      {
        breakpoint: 992,
        settings: {
          dots: false,
        }
      }
    ] 
  });
}
