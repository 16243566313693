import { accordion } from './components/Accordion';
import { filter } from './components/Filter';
import { animation } from './components/Animate';
import { navigation } from './components/Navigation';
import { gallery } from './components/Fancybox'
import { numberCount } from './components/NumberCount';
import { fileInput } from './components/FileInput';
import { validation } from './components/Validation';
import { tabs } from './components/Tabs';
import { initScrollMagic } from './components/ScrollMagicParameters';
import { owlSliders } from './components/OwlCarouselParameters';
import { slickSliders } from './components/SlickSlider';
import { collapse } from './components/Collapse';

accordion();

filter();

animation();

navigation();

gallery();

numberCount();

fileInput();

validation();

tabs();

initScrollMagic();

owlSliders();

slickSliders();

collapse();
