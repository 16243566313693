export const tabs = () => {
  $('.js-tab-wrapper').each(function() {
    const element = $(this), 
          tabLink = element.find("[data-tab]"),
          tabContent = element.find("[data-content]"),
          backBtn = element.find('.js-back'),
          isOfficeTabs = element.hasClass('js-offices-tabs');

    tabLink.on('click', function() {
      const $this = $(this),
            index = $this.data('tab'),
            content = element.find(`[data-content=${index}]`);
          
      if(isOfficeTabs) {
        backBtn.on('click', () => {
          const visibleElem = $('.js-planet').find('[data-tab].active');
          visibleElem.addClass('out-zoom');
          tabLink.removeClass('active');
          tabContent.removeClass('active');
          $('.js-planet').removeClass('unactive');
          setTimeout(function() {
            visibleElem.removeClass('out-zoom');
          }, 500);
        })
        
        $(document).on('click', (e) => { 
          if (!tabLink.is(e.target)
              && tabLink.has(e.target).length === 0) {
                const visibleElem = $('.js-planet').find('[data-tab].active');
                visibleElem.addClass('out-zoom');
                element.removeClass('show');
                tabLink.removeClass('active');
                tabContent.removeClass('active');
                $('.js-planet').removeClass('unactive');
                setTimeout(function() {
                  visibleElem.removeClass('out-zoom');
                }, 500);
          }
        })
        $('.js-planet').addClass('unactive');
      }

      tabLink.removeClass('active');
      $this.addClass('active');
      tabContent.removeClass('active');
      content.addClass('active');
  })
})
}
