import {TweenMax} from "gsap/TweenMax";
import ScrollMagic from 'scrollmagic';
import "imports-loader?define=>false!scrollmagic/scrollmagic/uncompressed/plugins/animation.gsap";

export const initScrollMagic = () => {
  if ($(window).width() > 767) {
    let controller = new ScrollMagic.Controller();

    $('.js-parallax').each(function () {

      let offsetY = this.dataset.offsety || 0,
        offsetX = this.dataset.offsetx || 0,
        parallax = new ScrollMagic.Scene({
          triggerElement: this,
          triggerHook: 1,
          duration: '150%'
        })
          .setTween(TweenMax.from(this, 1, {y: offsetY, x: offsetX, ease: Linear.easeNone}))
          .addTo(controller);
    });
  }
}
